<template>
  <div class="profile-view">
    <control-panel>
      <template v-slot:side-menu>
        <div class="side-menu">
          <div class="side-menu__top">
            <div
              class="side-menu__item"
              v-for="(item, index) in profileTopSideMenuItemsLocalized"
              :key="'top-side-menu-item-' + index"
              @click="profileSideMenuActiveItem = item"
              :is-active="item.name === profileSideMenuActiveItem.name"
            >
              {{item.text}}
            </div>
          </div>
          <div class="side-menu__bottom">
            <div 
              class="side-menu__item"
              v-for="(item, index) in profileBottomSideMenuItemsLocalized"
              :key="'bottom-side-menu-item-' + index"
              @click="profileSideMenuActiveItem = item"
              :is-active="item.name === profileSideMenuActiveItem.name"
            >
              {{item.text}}
            </div>
          </div>
        
          <div class="side-menu__support-button">
            <h-button
              class="button--join"
              filled blue x-small-padding block
            >
              <template v-slot:icon-left>
                <img 
                  src="../assets/icon-telegram-bubble-white.svg" 
                  alt="Кнопка поддержка"
                >
              </template>
              {{$localize.get('support')}}
            </h-button>
          </div>
        </div>
      </template>

      <template v-slot:content>
        <div 
          class="content"
          v-if="profileSideMenuActiveItem.name === 'my_specialists'"
        >
          <div class="content__header">
            <div class="content__header__title">
              {{$localize.get('my_specialists')}}
            </div>
            <h-button
              class="button--join"
              filled green x-small-padding
            >
              {{$localize.get('add_specialist')}}
              <template v-slot:icon-right>
                <v-icon color="#fff">mdi-plus</v-icon>
              </template>
            </h-button>
          </div>

          <div class="content__main">
            <data-table 
              :filterData="tables.specialists.filterData"
              :setFilterData="setFilterData"
              :headerData="profileSpecialistsTableHeaderData"
              :tableData="profileSpecialistsTableData"
              :itemStyles="{
                gridTemplateColumns: '64px 2fr 1fr 1fr 1fr 1fr'
              }"
            ></data-table>
          </div>
        </div>
      </template>
    </control-panel>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Profile',
  data() {
    return {
      tables: {
        specialists: {
          filterData: []
        }  
      }
    }
  },
  mounted () {
    this.fetchTablesSpecialistsFilterData()
  },
  watch: {
    selectedLanguage () {
      this.fetchTablesSpecialistsFilterData()
    }
  },
  computed: {
    ...mapGetters([
      'profileTopSideMenuItemsLocalized',
      'profileBottomSideMenuItemsLocalized',
    ]),
    profile () {
      return this.$store.state.profile
    },
    selectedLanguage () {
      return this.$store.state.storageData.settings.localization.selectedLanguage
    },
    profileSideMenuActiveItem: {
      get () {
        return this.$store.state.views.profile.sideMenu.activeItem
      },
      set (value) {
        this.$store.state.views.profile.sideMenu.activeItem = value
      }
    },
    profileSpecialistsTableDataGrades () {
      return [...new Set(this.profileSpecialistsTableData.map(item => item.properties.grade))]
    },
    profileSpecialistsTableDataStatuses () {
      return [...new Set(this.profileSpecialistsTableData.map(item => item.properties.status.text))]
    },
    profileSpecialistsTableData () {
      let specialists = JSON.parse(JSON.stringify(this.profile.specialists))
      let data = []
      specialists.forEach((item) => {
        let dataObject = {
          onClick: () => {
            this.$store.dispatch('OPEN_VACANCY_EDITOR', {type: 'edit'})
          },
          properties: {
            id: item.id,
            description: {
              name: item.name,
              role: item.role,
            },
            format: item.format,
            grade: item.grade,
            status: item.status,
            actions: {
              ...item.actions,
              downloadCV: {
                actionClass: 'cv',
                iconClass: 'download-icon',
                icon: require('../assets/icon-download.svg'),
                tooltipText: this.$localize.get('download'),
                onClick: (item) => {
                  this.$store.dispatch('DOWNLOAD_CV', item)
                }
              },
              delete: {
                actionClass: 'delete',
                iconClass: 'delete-icon',
                icon: require('../assets/icon-delete-red.svg'),
                tooltipText: this.$localize.get('delete'),
                onClick: (data) => {
                  this.$store.dispatch('DELETE_TABLE_ITEM', data)
                }
              },
            }
          }
        }
        data.push(dataObject)
      })
      return data
    },
    profileSpecialistsTableHeaderData () {
      return [
        {
          prop: 'id',
          title: '№',
        },
        {
          prop: 'description',
          title: this.$localize.get('nameRole'),
        },
        {
          prop: 'format',
          title: this.$localize.get('format'),
        },
        {
          prop: 'grade',
          title: this.$localize.get('grade'),
        },
        {
          prop: 'status',
          title: this.$localize.get('status'),
        },
        {
          prop: 'actions',
          title: this.$localize.get('actions'),
        },
      ]
    },
  },
  methods: {
    fetchTablesSpecialistsFilterData () {
      this.tables.specialists.filterData = [
        {
          prop: 'grade',
          title: this.$localize.get('grade'),
          multiple: true,
          type: true,
          items: this.profileSpecialistsTableDataGrades,
          selectedItems: this.profileSpecialistsTableDataGrades,
        },
        {
          prop: 'status',
          title: this.$localize.get('status'),
          multiple: true,
          type: true,
          items: this.profileSpecialistsTableDataStatuses,
          selectedItems: this.profileSpecialistsTableDataStatuses,
        },
        {
          title: this.$localize.get('sort_by'),
          prop: 'sortType',
          multiple: false,
          items: this.profileSpecialistsTableHeaderData,
          selectedItems: this.profileSpecialistsTableHeaderData[0],
          sortDirection: 'asc'
        },
      ]
    },
    setFilterData (params) {
      this.tables.specialists.filterData = params
    }
  },
}
</script>

<style lang="scss">
.profile-view {
  height: 100%;
}
</style>